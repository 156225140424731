<template>
  <div class="container position-relative">
    <div class="chek-header">
      <router-link to="/">
        <img src="@/assets/img/logo-black.svg" alt="" />
      </router-link>
    </div>
    <div class="row box-wizard" v-if="detail">
      <div class="col-md-7">
        <h2 class="mb-5 mt-5">
          Thank you! {{ detail.status == 'OV' ? 'Your order has been received' : 'We are waiting for your payment' }}
        </h2>
        <h5 class="mb-5">
          Confirmation letter has been sent to your email ({{ detail.member_email }})
        </h5>
        <h5 class="bold mb-3">Hello, {{ userData ? userData.full_name : detail.billing_address.name }}.</h5>
        <div class="bg-gold d-inline-block text-white">
          <h5 class="no-bold px-2 pt-2">Please routinely check your email for messages from us</h5>
        </div>
        <h5 class="no-bold mt-5 w-lg-50">
          <template v-if="detail.status == 'OV'">
            Your order has been successfully completed and will
            be delivered to you in the near future. You can
            track the delivery status from your email
          </template>
          <template v-else>
            Your order will be processed once you've completed your payment.<br/>
            Please ignore this message if you've completed your payment.
          </template>
          <!-- <template v-if="userData">
            or click <router-link :to="`/order-detail/${orderId}`" target="_blank">here</router-link>
          </template>. -->
        </h5>
        <h5 v-if="!isLoggedIn && guestSession" class="mt-4">You can register by clicking register now to track your order easier</h5>
        <button class="btn btn-wz-outline mr-2" v-b-modal.modalRegister v-if="!isLoggedIn && guestSession">Register Now</button>
        <router-link to="/" class="btn btn-wz-outline">Return to Homepage</router-link>
      </div>
      <div class="col-md-5">
        <h4>Order Summary</h4>
        <div class="d-grid">
          <div class="cell_1x1 text-summary text-nowrap">Order ID</div>
          <div class="cell_1x1 pl-3">{{ orderId }}</div>
          <div class="cell_1x1 text-summary text-nowrap">Date</div>
          <div class="cell_1x1 pl-3">{{ formatDate(detail.order_date_string) }}</div>
          <div class="cell_1x1 text-summary text-nowrap">Shipping Detail</div>
          <div class="cell_1x1 pl-3">
            <h5>{{ detail.billing_address.name }}</h5>
            <p>
              {{ detail.billing_address.address }},
              {{ detail.billing_address.city }},
              {{ detail.billing_address.districts }},
              {{ detail.billing_address.regions }} {{ detail.billing_address.postal_code }}<br/>
              {{ detail.billing_address.phone[0] == '8' ? 0 : '' }}{{ detail.billing_address.phone }}
            </p>
          </div>
          <template v-for="(merchant, i) in detail.sellers">
            <div class="cell_2x2" :key="`merchant-${i}`">
              {{ merchant.seller_name }}
              <div class="ml-auto badge badge-danger" :class="{'cursor-pointer' : !merchant.courier_code}" style="line-height: 18px;" @click="showPickupMap(merchant)">
                <template v-if="merchant.courier_code">
                  <fawesome-pro icon="truck"/> {{ merchant.courier_service_name }}
                </template>
                <template v-else>
                  <fawesome-pro icon="map-marker-alt"/> {{ merchant.pick_up_point_name }} - {{ merchant.pick_up_point_contact }}
                </template>
              </div>
            </div>
            <template v-for="(item, j) in merchant.items">
              <div class="cell_1x1" :key="`merchant-${i}-item-${j}-img`">
                <img :src="item.image_url" :title="item.product_name" :alt="item.product_name" class="img-fluid" @error="replaceByDefaultImage" >
              </div>
              <div class="cell_1x1 d-flex" :key="`merchant-${i}-item-${j}-info`">
                <div class="m-0 product-info">
                  <div class="product-brand">
                    <a href="#">{{ item.brand_name }}</a>
                  </div>
                  <div class="product-name">
                    <a href="#">{{ item.product_name }}</a><br/>
                    {{ item.color[0] != '-' ? item.color : '' }} {{ item.size[0] != '-' ? `/ ${item.size}` : '' }} 
                  </div>
                  <div></div>
                </div>
                <div class="ml-auto p-1">{{ item.quantity }}x</div>
                <div class="product-price text-right p-1" :class="{'sale-price' : item.price_display < item.price}">
                  IDR {{ formatPrice(item.price_display) }}
                  <span v-if="item.price_display < item.price" class="old-price">IDR {{ formatPrice(item.price) }}</span>
                </div>
              </div>
            </template>
          </template>
          <div class="cell_1x1 text-nowrap"><span>Payment Method</span></div>
          <div class="cell_1x1 text-right"><h5>{{ detail.payment_method }}</h5></div>
          <div class="payment-method-desc-box d-inline-block cell_2x2 mb-2"  v-html="detail.payment_description" v-if="detail.payment_description">
          </div>
          <div class="cell_1x1 text-nowrap">Subtotal</div>
          <div class="cell_1x1 text-right"><h5>Rp {{ formatPrice(detail.sub_total) }}</h5></div>
          <div class="cell_1x1 text-nowrap">Shipping</div>
          <div class="cell_1x1 text-right"><h5>Rp {{ formatPrice(detail.shipping) }}</h5></div>
          <template v-if="voucherShipping() > 0">
            <div class="cell_1x1 text-nowrap">Voucher Shipping</div>
            <div class="cell_1x1 text-right"><h5>Rp {{ formatPrice(voucherShipping()) }}</h5></div>
          </template>
          <template v-if="voucherPromo() > 0">
            <div class="cell_1x1 text-nowrap">Voucher Promo</div>
            <div class="cell_1x1 text-right"><h5>Rp {{ formatPrice(voucherPromo()) }}</h5></div>
          </template>
          <template v-if="voucherBusPartner() > 0">
            <div class="cell_1x1 text-nowrap">Voucher Bus. Partner</div>
            <div class="cell_1x1 text-right"><h5>Rp {{ formatPrice(voucherBusPartner()) }}</h5></div>
          </template>
          <div class="cell_1x1 text-nowrap">Total</div>
          <div class="cell_1x1 text-right"><h5>Rp {{ formatPrice(detail.total) }}</h5></div>
        </div>
      </div>
    </div>
    <b-modal id="modal-pickup-map" centered hide-header hide-footer static lazy>
      <div id="map" class="map rounded" style="height: 200px;" v-html="map && map.outerHTML"></div>
      <template v-if="selectedMerchant">
        <div class="border p-2 rounded mt-2">
          <h5>{{ selectedMerchant.pick_up_point_name }}</h5>
          <div>{{ selectedMerchant.pick_up_point_address }}</div>
          <div>{{ selectedMerchant.pick_up_point_contact }}</div>
        </div>
        <small>
          Operating days: {{ selectedMerchant.pick_up_point_operating_day || 'Weekdays' }} ({{
            selectedMerchant.pick_up_point_operating_start_time.substring(0,5)
          }} WIB - {{
            selectedMerchant.pick_up_point_operating_end_time.substring(0,5)
          }} WIB)
        </small>
      </template>
    </b-modal>
    <ModalRegister v-if="!isLoggedIn && guestSession" />
  </div>
</template>
<script>
import { toaster } from '@/_helpers';
import { Loader } from '@googlemaps/js-api-loader';
const ModalRegister = () => import("@/components/modals/ModalRegister");

import moment from 'moment'

export default {
  name: 'ThankYou',
  data() {
    return {
      detail: null,
      selectedMerchant: null,
      map: null,
    }
  },
  components: {
    ModalRegister,
  },
  computed: {
    orderId() {
      return this.$route.params.code;
    },
    userData() {
      return this.$store.getters.getUserData;
    },
    isLoggedIn() {
      return this.$store.getters.getIsLoggedIn;
    },
    guestSession() {
      return this.$store.getters.getGuestSession;
    }
  },
  mounted() {
    if (!/HSO/.test(this.orderId)) return this.$router.replace("/");
    this.getOrderDetail(this.orderId);
  },
  methods: {
    showPickupMap(merchant) {
      if (!merchant.courier_code) {
        this.selectedMerchant = merchant
        this.$bvModal.show('modal-pickup-map')
        this.$nextTick(() => {
          const loader = new Loader({
            apiKey: "AIzaSyD4OaFeki2cLk8pqqgnoNum-iHRe7OzWKE",
            version: "weekly",
            libraries: ["places"],
          })
          const mapContainer = document.getElementById("map")
          loader.load().then(() => {
            // https://stackoverflow.com/a/66557044
            const google = window.google;
            const map = new google.maps.Map(mapContainer, {
              center: {
                lat: merchant.pick_up_point_latitude,
                lng: merchant.pick_up_point_longitude,
              },
              zoom: 18,
              mapTypeId: google.maps.MapTypeId.ROADMAP,
            });
            new google.maps.Marker({
              position: {
                lat: merchant.pick_up_point_latitude,
                lng: merchant.pick_up_point_longitude,
              },
              map,
            });
          })
        })
      }
    },
    async getOrderDetail(orderId) {
      try {
        const res = await this.$api.order.getOrderDetailGeneral(orderId)
        if (res.status === 200) {
          this.detail = res.data.data
          console.log(this.detail);
        }
        else {
          toaster.make(res.data.message, 'danger')
          this.$router.push('/')
        }
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    voucherShipping() {
      return this.detail.sellers
        .map(el => el.voucher_used.find(({voucher_name}) => voucher_name.indexOf('<Shipping>') >= 0))
        .reduce((sum, el) => sum + (el ? el.value : 0), 0)
    },
    voucherPromo() {
      return this.detail.sellers
        .map(el => el.voucher_used.find(({voucher_name}) => voucher_name.indexOf('<') < 0))
        .reduce((sum, el) => sum + (el ? el.value : 0), 0)
    },
    voucherBusPartner() {
      return this.detail.sellers
        .map(el => el.voucher_used.find(({voucher_name}) => voucher_name.indexOf('<Reseller>') >= 0))
        .reduce((sum, el) => sum + (el ? el.value : 0), 0)
    },
    formatPrice(value) {
      return value ? value.toLocaleString('id') : value
    },
    formatDate(value, format = 'D MMMM YYYY') {
      return value ? moment(String(value)).format(format).toString() : value;
    },
    replaceByDefaultImage(img) {
      img.target.src = require("@/assets/img/product-default.png");
    },
  },
};
</script>
<style scoped>
/* Payment Method Description Box */
.payment-method-desc-box {
  border: 2px solid #d89700;
  padding: .5rem;
}

.payment-method-desc-box:deep(p:last-child) {
  margin-bottom: 0;
}

.container {
  max-width: 1568px;
}
.d-grid {
  position: relative;
  display: grid;
  grid-template-columns: min-content auto;
}
@media only screen and (max-width: 992px) {
  .cart-item {
    margin-left: 35px;
  }
}
.cell_2x2 {
  grid-column: auto/span 2;
  display: flex;
}
.product-price {
  line-break: unset;
}
</style>
